module services {
    export module master {
        export class refundSettingService implements interfaces.master.IRefundSettingService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getList(): ng.resource.IResourceClass<interfaces.master.IRefundSettingDisplay> {
                return this.$resource<interfaces.master.IRefundSettingDisplay>(this.ENV.DSP_URL + "RefundSetting/GetList", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1,
                            }
                        }
                    });
            }

            getRefundSettingDetail(): ng.resource.IResourceClass<interfaces.master.IRefundSetting> {
                return this.$resource<interfaces.master.IRefundSetting>(this.ENV.DSP_URL + "RefundSetting/GetRefundSettingDetail",
                    {
                        refId: "@refId",
                    }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    }
                );
            }

        
            saveRefundSetting(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "RefundSetting/SaveRefundSetting", {
                });
            }   

        }
    }
    angular.module("app").service("refundSettingService", services.master.refundSettingService);
}